import React from 'react'
import './ProviderStart.css';
import Question from './Question';

function ProviderStart() {
  return (
    <div className="providerStart">
      <div className="headline">
				<h2>STARTSEITE</h2>
			</div>
      <main>
        <div className="generalInformation container">
          <h3 style={{textTransform: "uppercase"}}>Willkommen bei deinem Event-Store für Alles!</h3>
          <p>Interactively revolutionize high-payoff alignments for cross-unit networks. Quickly productivate web-enabled products rather than equity invested growth strategies. Competently build prospective materials with premier e-business. Objectively mesh synergistic applications and.</p>
          <p>Interactively revolutionize high-payoff alignments for cross-unit networks. Quickly productivate web-enabled products rather than equity invested growth strategies. Competently build prospective materials with premier e-business. Objectively mesh synergistic applications and.</p>
          <p>Interactively revolutionize high-payoff alignments for cross-unit networks. Quickly productivate web-enabled products rather than equity invested growth strategies. Competently build prospective materials with premier e-business. Objectively mesh synergistic applications and.</p>
        </div>
        <div className="random container" id="random1">
          <h3>EXTRA 2</h3>
          <p style={{paddingTop: "10px"}}>Interactively revolutionize high-payoff alignments for cross-unit networks.</p>
          <div style={{fontWeight: "700",paddingTop: "15px", color: "#F9B300"}}>Anfrage senden</div>
        </div>
        <div className="random  container" id="random2">
          <h3>EXTRA 1</h3>
          <p style={{paddingTop: "10px"}}>Interactively revolutionize high-payoff alignments for cross-unit networks.</p>
          <div style={{fontWeight: "700",paddingTop: "15px", color: "#F9B300"}}>Anfrage senden</div>
        </div>
        <div className="questions">
          <h3>GUTE FRAGEN</h3>
          <Question title="Was ist iVenti?">Der Event-Store iVenti ist ein regionales und überregionales Online-Portal, auf dem Suchende einfach alles für ihr Event finden können. Anbieter:innen aus der Eventbranche können Inserate schalten, die dann von Interessent:innen gefunden werden. Neben der Suche über Event-Rubriken gibt es auch die Suche über „Inspiration” und „Schnäppchen”.</Question>
          <Question title="Keine Agentur! Nur du und die Suchenden.">Suchende treten immer direkt mit dir als Dienstleister:in in Kontakt. iVenti bleibt bei diesem Kontakt außen vor und hat keine Kenntnis von den Inhalten eurer Kommunikation. Von iVenti hast du bei dieser Kommunikation keinerlei Kosten zu erwarten.</Question>
          <Question title="Was für Inserat-Typen gibt es bei iVenti?">Auf iVenti.de gibt es 2 Arten von Inseraten: iVenti ZERO (kostenlos) und iVenti PLUS (kostenpflichtig). iVenti ZERO ist ein kleines Inserat mit 1 Foto und wenigen Basisinformationen. iVenti PLUS ist ein ganzseitiges Inserat mit bis zu 18 Fotos, 2 Filmen und umfangreichen Informationen zum Angebot. Über beide Inserate können Suchende kostenlos Kontakt mit den Anbieter:innen aufnehmen. In den Suchergebnissen werden beide Inserattypen in der Größe von iVenti ZERO angezeigt. Über den Button „Mehr Infos und Bildergalerie” bei iVenti-PLUS-Inseraten gelangen Suchende dann zum ganzseitigen Inserat. iVenti-PLUS-Inserate werden im vorderen Bereich der Suchergebnisse angezeigt, iVenti-ZERO-Inserate im hinteren Bereich.</Question>
          <Question title="Wie lange wird ein Inserat bei iVenti angezeigt?">Beide Inserat-Typen (iVenti ZERO und iVenti PLUS) werden jeweils für 1 Jahr auf iVenti.de gebucht und angezeigt. Sie können jederzeit bearbeitet und verlängert werden.</Question>
          <Question title="Was ist  unter „Rubriken“ zu finden?">Im Bereich „Rubriken“ werden sämtliche iVenti-Inserate angezeigt, sortiert nach Rubriken und Unterkategorien. Was immer bei iVenti angeboten wird: In „Rubriken“ wird es gefunden.</Question>
          <Question title="Wozu dient der Bereich „Inspiration”?">Viele unserer Besucher:innen nutzen für ihre Suche einfach den komfortablen iVenti-Bereich „Inspiration“. Hier finden sie zu ausgewählten Eventarten wertvolle Anregungen. Und hier finden sie auch dich –  mit deinem Inserat, das du punktgenau auf die Bedürfnisse deiner Kund:innen im gewählten Inspirationsbereich (z. B. „Hochzeit“) zugeschnitten hast. Ein Inserat im Bereich „Inspiration“ ist 1 Jahr sichtbar und mit den zusätzlichen, einmaligen Kosten von 49,– € netto verbunden.</Question>
          <Question title="Was ist ein „Schnäppchen”?">Um die Attraktivität deines iVenti-PLUS-Angebotes kurzfristig zu steigern, kannst du dein Inserat als „Schnäppchen“ kennzeichnen. Schnäppchen-Angebote sind immer 7 Tage gültig und werden im iVenti-Bereich „Schnäppchen“ exklusiv angezeigt. Außerdem erscheinen sie mit ihrem auffälligen, goldenen „Schnäppchen“-Header in den regulären Suchergebnissen unter „Rubriken“. Ein Schnäppchen-Inserat ist mit den zusätzlichen, einmaligen Kosten von 20,– € netto verbunden.</Question>
          <Question title="Wozu gibt es den „Notdienst”-Button / Notdienst-Service?">Werde ein:e Retter:in in der Not! iVenti bietet eine Notdienst-Funktion an für Menschen, denen bei ihrer Eventplanung ein:e Dienstleister:in kurzfristig abgesprungen ist. Aktivieren Suchende auf iVenti.de den „Notdienst”-Button, werden ausschließlich Inserate von Anbieter:innen angezeigt, die zum angefragten Zeitpunkt einen Notdienst bieten.Wenn du als Anbieter:in einen Notdienst-Service für dein iVenti-Inserat bereitstellen möchtest, kannst du für die gesamten Laufzeit deines Inserates beliebig viele Tage festlegen. Deine Angaben kannst du jederzeit ändern. Der Notdienst ist eine Extra-Leistung für deine Kunden und macht aus dir ein:e Held:in. Es entstehen den Suchenden und den Anbieter:innen durch den Notdienst-Service keine Kosten.</Question>
          <Question title="Wie treten Suchende mit Anbieter:innen in Kontakt?">In jedem iVenti-Inserat gibt es einen Button „Kostenlose Anfrage”. Hier können Suchende über ein Kontaktformular eine Gratis-Anfrage direkt an die Anbieter:innen versenden. Die weitere Kommunikation zwischen Interessent:in und Anbieter:in läuft dann über deren eigene E-Mail-Adressen. iVenti dient bei dieser Kommunikaton lediglich als erster Vermittler, ist selbst an dieser Kommunikation nicht beteiligt und erhält keinerlei Kenntnis von den Gesprächsinhalten. Ebenso speichert iVenti keinerlei Daten dieser Kommunikation.</Question>
          <Question title="Welchen Vorteil haben Suchende bei iVenti?">Wer ein kleines oder großes Event plant, findet auf iVenti schnell und einfach regionale und überregionale Anbieter:innen aus der gesamten Eventbranche. Suchende können für sie interessante Inserate in Merklisten zusammenfassen (hierzu müssen sie einen kostenlosen iVenti-Account anlegen). Über den Button „Kostenlose Anfrage” können Suchende über ein Kontaktformular eine kostenlose Anfrage direkt an die Anbieter:innen versenden. Die Nutzung von iVenti ist für Suchende völlig kostenlos.</Question>
          <Question title="Welchen Vorteil haben Anbieter:innen von iVenti?">Mit ihrem Inserat können Dienstleister:innen aus allen Bereichen der Eventbranche regional und überregional auf sich aufmerksam machen und schnell und einfach gefunden werden. So können sie in kurzer Zeit ihre Bekanntheit und ihren Kundenkreis vergrößern und ihre Umsätze steigern. In ihrem iVenti-Account können Anbieter:innen ihre Inserate, ihr Profil und Statistiken verwalten. Es gibt kleine, kostenlose Inserate (iVenti ZERO) und ganzseitige, kostenpflichtige Inserate (iVenti PLUS). Ein Inserat wird für 1 Jahr gebucht und kann jederzeit bearbeitet und verlängert werden. Neben den Gebühren für Inserate fallen keinerlei zusätzliche Kosten oder Provisionen an.</Question>
          <Question title="iVenti GO – was ist das?">Als Dienstleister:innen kannst du eine Bestellung nicht anliefern? Auch dein:e Kund:in hat keine Möglichkeit, bestellte Artikel abzuholen? Dann lass „das gesamte Event“ einfach von iVenti GO liefern und nach der Feier wieder abholen!</Question>
        </div>
      </main>
    </div>
  )
}

export default ProviderStart