import React, { useRef, useState } from 'react';
import Header from './Header';
import CircularProgress from '@mui/material/CircularProgress';
import { useUserContext } from '../../context/UserContext';
import { useNavigate, Navigate, Link } from 'react-router-dom'
import PopUp from '../PopUp';


function LoginProvider() {

	const emailRef = useRef()
	const passwordRef = useRef()

	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")

	const { signInUser, user } = useUserContext()

	async function signInNewUser(){
		setLoading(true)
		signInUser(emailRef.current.value, passwordRef.current.value).then(() => {
			navigate("/dashboard/start")
		}).catch((err) => {
			setLoading(false)
			setError("Benutzername und Passwort stimmen nicht überein")
		})
	}

	return (
		<>
			{user && <Navigate to="/Dashboard/Start"/>}
			{error && <PopUp message={error} setError={setError}/>}
			<div className="LoginProvider">
				<Header headline={"WILLKOMMEN BEI iVENTI"} text={"Zeige deine Region, was du zu bieten hast!"} color={"#F6F6F5"} />
				<div className="headlineNormal">Hier einloggen</div>
				<div style={{maxWidth: "90%", width: "500px", margin: "auto", paddingTop: "20px", textAlign: "center"}}>
					<input ref={emailRef} className="textField" type="text" placeholder="E-Mail-Adresse*" />
					<input ref={passwordRef} className="textField" type="password" placeholder="Mein Passwort*" />
				</div>
				<div style={{cursor: "pointer" ,width: "100%", display: "flex", alignItems: "center", margin: "20px 0 50px 0", flexDirection: "column"}}>
					<Link to="/register" style={{marginBottom: "20px" ,fontSize: "12px"}}>Du hast noch keinen Account? Hier registrieren</Link>
					{loading ? 
						<button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
						:
						<button onClick={signInNewUser} className="submitAdButton">ANMELDEN</button>
					}
					<Link to="/reset" style={{cursor: "pointer", marginTop: "10px" ,fontSize: "10px"}}>Passwort vergessen?</Link>
				</div>
			</div>
		</>
	)
}

export default LoginProvider;
