import React, { useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring'
import { Navigate } from 'react-router-dom'
import { useUserContext } from '../../context/UserContext';
import './Dashboard.css'
import CartIcon from '../../img/cartIcon.svg'
import { NavLink, Outlet } from 'react-router-dom'
import PopUp from '../PopUp';
import IventiLogo from '../../img/LogoIventiWeiß.svg'
import TopShapeGrey from '../../img/TrennerObenWeißSmall.svg'


function Dashboard() {	

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	const [active, setActive] = useState("")

	const { user, logoutUser, forgotPassword} = useUserContext()
	
	async function reset(){
		setLoading(true)
        forgotPassword(user.email).then(() => {
            setError("Wir haben dir eine E-Mail gesendet")
            setLoading(false)
        }).catch((err) => {
            setError("Es ist ein Fehler aufgetreten", err)
            setLoading(false)
        })
	}	

	return (
		<>
		{error && <PopUp message={error} setError={setError}/>}
		{!user ? "" :
		<>
		{!user.emailVerified &&
			<div style={{display: "flex", textTransform: "uppercase", fontSize: "14px", justifyContent: "center", fontWeight: "700", backgroundColor: "#FAB300", padding: "10px 0 7px 0", color: "white"}} className="infoBar">
				<div>Bestätige deine E-Mail-Adresse. Danach kannst du Inserate anlegen.</div>
				<div onClick={reset} style={{paddingLeft: "5px", textDecoration: "underline"}}>Bestätigung erneut senden.</div>
			</div>
		}
		<div className="Dashboard">
			<div className="db_header">
				<img className="logo" src={IventiLogo} />
				<div style={{textAlign: "center"}}>
					<div style={{textTransform: "uppercase"}} className="db_headline">HEY {user.displayName}!</div>
					<div className="db_headline">WILLKOMMEN IN DEINEM DASHBOARD</div>
				</div>
			</div>
			<div className="lineBreakTopWhite">
					<img style={{display: "block"}} src={TopShapeGrey} />
			</div>
			<div className="db_content">
				<div className="db_navigation">
					<NavLink to="start" className="db_button">STARTSEITE</NavLink>
					<NavLink to="ads" className="db_button">NEUES<br/>INSERAT IN<br/>"RUBRIKEN"</NavLink>
					<NavLink to="inspo" className="db_button">NEUES<br/>INSERAT IN<br/>"INSPIRATION"</NavLink>
					<NavLink to="adsProfile" className="db_button">MEINE<br/>INSERATE</NavLink>
					<NavLink to="profile" className="db_button">MEIN PROFIL</NavLink>
					<a onClick={logoutUser} className="db_button">LOG-OUT</a>
					<NavLink value="cart" to="cart" className="db_button">
						<img className="db_cartItem" src={CartIcon} />
					</NavLink>
				</div>
			</div>
		</div>
		<Outlet />
		</>
		}
		</>
		
	)
}

export default Dashboard;
