import React from 'react';
import CelebrationIcon from '@mui/icons-material/Celebration';
import './SmallAd.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoIcon from '@mui/icons-material/Info';

function SmallAd(props) {
	return (
		<div className="SmallAd">
			<div className="sa_image">
				<img src={props.image} />
			</div>
			<div className="sa_info">
				<div className="sa_headline">{props.headline}</div>
				<div className="sa_iconContainer">
					<CelebrationIcon />
					<div className="sa_text">{props.categorie}</div>
				</div>
				<div className="sa_iconContainer">
					<InfoIcon /> 
					<div className="sa_text">{props.service}</div>
				</div>
				<div className="sa_iconContainer">
					<InfoIcon />
					<div className="sa_text">{props.name}</div>
				</div>
				<div className="sa_iconContainer">
					<LocationOnIcon />
					<div className="sa_text">{props.address}</div>
				</div>
				{props.type !== "zero" &&
				<div className="sa_moreInfo">
					<div>Mehr Infos und Bildergalerie</div>
				</div>
				}
			</div>
		</div>
	)
}

export default SmallAd;
