// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth'
 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBFUWfW81nAuyvn2budQJOsaks2dsQdXUs",
  authDomain: "iventi-adaf4.firebaseapp.com",
  projectId: "iventi-adaf4",
  storageBucket: "iventi-adaf4.appspot.com",
  messagingSenderId: "801408263724",
  appId: "1:801408263724:web:fbb80efd830d76d6089389"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)
export const db = getFirestore(app)
export const auth = getAuth(app)