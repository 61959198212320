import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components'

function Loader() {

	const FlexDiv = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100px;
	`
	

	return (
		<FlexDiv>
			<CircularProgress style={{ color: "orange" }} />
		</FlexDiv>
	)
}

export default Loader;
