import './App.css';
import './MobileDesign.css'
import Search from './components/Search';

import React, { createElement } from 'react';
import Gallery from './components/Gallery';
import CreateAd from './components/provider/CreateAd';
import MapsAutocomplete from './components/provider/MapsAutocomplete';
import ProviderGallery from './components/provider/ProviderGallery';
import { useUserContext } from './context/UserContext';
import Auth from './components/Auth'

import { db } from './Firebase'
import { collection, getDocs, getDoc, doc, query, where, deleteDoc } from 'firebase/firestore';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Summary from './components/provider/Summary';
import LandingPage from './components/provider/LandingPage';
import Dashboard from './components/provider/Dashboard';
import LoginProvider from './components/provider/LoginProvider';
import VideoTest from './components/provider/VideoTest';
import AdsProfile from './components/provider/AdsProfile';
import ResetPasswortProvider from './components/provider/ResetPasswordProvider';
import RegisterProvider from './components/provider/RegisterProvider';
import Profile from './components/provider/Profile';
import ProtectedRoutes from './components/ProtectedRoutes';
import EditAd from './components/provider/EditAd';
import CreateInspo from './components/provider/CreateInspo';
import EditInspo from './components/provider/EditInspo';
import ProviderStart from './components/provider/ProviderStart';
import ProcessOrder from './components/provider/ProcessOrder';
import Landing from './components/provider/Landing';
import CheckAds from './components/admin/CheckAds';
import ShowAd from './components/ShowAd';




function App() {

  return (
	<Router className="appContainer">  
		<Routes>
			<Route element={<ProtectedRoutes />}>
				<Route path="/dashboard" element={<Dashboard />}>
					<Route path="start" element={<ProviderStart />}/>
					<Route path="editAds/:id" element={<EditAd />}/>
					<Route path="editInspos/:id" element={<EditInspo />}/>
					<Route path="ads" element={<CreateAd />}/>
					<Route path="cart" element={<Summary />}/>
					<Route path="adsProfile" element={<AdsProfile />}/>
					<Route path="profile" element={<Profile />}/>
					<Route path="inspo" element={<CreateInspo />}/>
				</Route>
			</Route>

			<Route path="/login" element={<LoginProvider />}/>
			<Route path="/register" element={<RegisterProvider />}/>
			<Route path="/reset" element={<ResetPasswortProvider />}/>
			<Route path="/thanks/:id" element={<ProcessOrder />}/>
			<Route path="/admin" element={<CheckAds />}/>
			<Route path="/" element={<Landing />}/>
			<Route path="/test" element={<ShowAd />}/>
		</Routes>
	</Router>
  );
}

export default App;
