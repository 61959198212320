import React, { useState } from 'react';
import './Landing.css';
import Accordion from './Accordion';
import Pricecard from './Pricecard';
import PricecardElement from './PricecardElement';
import imgZero from '../../img/Zero.png';
import imgPlus from '../../img/Plus.png';
import IventiLogo from '../../img/LogoIventiWeiß.svg'
import { useNavigate } from 'react-router-dom';

import TopShape from '../../img/TrennerObenWeiß.svg'
import TopShapeGrey from '../../img/TrennerObenGrey.svg'
import BottomShapeGrey from '../../img/TrennerUntenWeiß.svg'
import HiddenImage from '../../img/Verlauf.png'
import Loader from '../Loader';


function Landing() {

	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)

	return (
		<>
		<img style={{display: "none"}} src={HiddenImage} onLoad={() => setLoading(false)} />
		{loading ? <Loader /> 
		:
		<main className="landing" style={{backgroundColor: "white"}}>
			
			<div className="Header">
				{/*<img className="logo" src={IventiLogo} /> */}
				<div style={{display: "flex", flexDirection: "column", padding: "60px 0 0 0", position: "relative"}} className="db_header">
					<img style={{width: "250px"}} src={IventiLogo} />
					<div style={{fontSize: "52px", marginTop: "30px"}} className="db_headline">DAS NEUE EVENT-PORTAL</div>
					<div style={{fontSize: "17px", marginTop: "5px", fontWeight: "700"}} className="db_headline db_headlineUnder">EXKLUSIVE FÜR ANBIETER:INNEN</div>
				</div>	
			</div>

			<div className="lineBreakTopWhite">
					<img style={{display: "block"}} src={TopShape} />
			</div>


			<section className="information" style={{backgroundColor: "white", paddingTop: "0px", paddingBottom: "40px"}}>
				<h2>Zeige deiner Region, was du zu bieten hast!</h2>
				<div className="bulletpoints">
					<div className="left">
						<Accordion title="Was ist iVenti?" content="Der Event-Store iVenti ist ein regionales und überregionales Online-Portal, auf dem Suchende einfach alles für ihr Event finden können. Anbieter:innen aus der Eventbranche können Inserate schalten, die dann von Interessent:innen gefunden werden. Neben der Suche über Event-Rubriken gibt es auch die Suche über „Inspiration” und „Schnäppchen”." />
						<Accordion title="Was für Inserat-Typen gibt es bei iVenti?" content="Auf iVenti.de gibt es 2 Arten von Inseraten: iVenti ZERO (kostenlos) und iVenti PLUS (kostenpflichtig). iVenti ZERO ist ein kleines Inserat mit 1 Foto und wenigen Basisinformationen. iVenti PLUS ist ein ganzseitiges Inserat mit bis zu 18 Fotos, 2 Filmen und umfangreichen Informationen zum Angebot. Über beide Inserate können Suchende kostenlos Kontakt mit den Anbieter:innen aufnehmen. In den Suchergebnissen werden beide Inserattypen in der Größe von iVenti ZERO angezeigt. Über den Button „Mehr Infos und Bildergalerie” bei iVenti-PLUS-Inseraten gelangen Suchende dann zum ganzseitigen Inserat. iVenti-PLUS-Inserate werden im vorderen Bereich der Suchergebnisse angezeigt, iVenti-ZERO-Inserate im hinteren Bereich." />
						<Accordion title="Was ist unter „Rubriken“ zu finden?" content="Im Bereich „Rubriken“ werden sämtliche iVenti-Inserate angezeigt, sortiert nach Rubriken und Unterkategorien. Was immer bei iVenti angeboten wird: In „Rubriken“ wird es gefunden." />
						<Accordion title="Was ist ein „Schnäppchen”?" content="Um die Attraktivität deines iVenti-PLUS-Angebotes kurzfristig zu steigern, kannst du dein Inserat als „Schnäppchen“ kennzeichnen. Schnäppchen-Angebote sind immer 7 Tage gültig und werden im iVenti-Bereich „Schnäppchen“ exklusiv angezeigt. Außerdem erscheinen sie mit ihrem auffälligen, goldenen „Schnäppchen“-Header in den regulären Suchergebnissen unter „Rubriken“. Ein Schnäppchen-Inserat ist mit den zusätzlichen, einmaligen Kosten von 20,– € netto verbunden." />
						<Accordion title="Wie treten Suchende mit Anbieter:innen in Kontakt?" content="In jedem iVenti-Inserat gibt es einen Button „Kostenlose Anfrage”. Hier können Suchende über ein Kontaktformular eine Gratis-Anfrage direkt an die Anbieter:innen versenden. Die weitere Kommunikation zwischen Interessent:in und Anbieter:in läuft dann über deren eigene E-Mail-Adressen. iVenti dient bei dieser Kommunikaton lediglich als erster Vermittler, ist selbst an dieser Kommunikation nicht beteiligt und erhält keinerlei Kenntnis von den Gesprächsinhalten. Ebenso speichert iVenti keinerlei Daten dieser Kommunikation." />
						<Accordion title="Welchen Vorteil haben Anbieter:innen von iVenti?" content="Mit ihrem Inserat können Dienstleister:innen aus allen Bereichen der Eventbranche regional und überregional auf sich aufmerksam machen und schnell und einfach gefunden werden. So können sie in kurzer Zeit ihre Bekanntheit und ihren Kundenkreis vergrößern und ihre Umsätze steigern. In ihrem iVenti-Account können Anbieter:innen ihre Inserate, ihr Profil und Statistiken verwalten. Es gibt kleine, kostenlose Inserate (iVenti ZERO) und ganzseitige, kostenpflichtige Inserate (iVenti PLUS). Ein Inserat wird für 1 Jahr gebucht und kann jederzeit bearbeitet und verlängert werden. Neben den Gebühren für Inserate fallen keinerlei zusätzliche Kosten oder Provisionen an." />
					</div>
					<div className="right">
						<Accordion title="Keine Agentur! Nur du und die Suchenden" content="Der Event-Store iVenti ist ein regionales und überregionales Online-Portal, auf dem Suchende einfach alles für ihr Event finden können. Anbieter:innen aus der Eventbranche können Inserate schalten, die dann von Interessent:innen gefunden werden. Neben der Suche über Event-Rubriken gibt es auch die Suche über „Inspiration” und „Schnäppchen”." />
						<Accordion title="Wie lange wird ein Inserat bei iVenti angezeigt?" content="Beide Inserat-Typen (iVenti ZERO und iVenti PLUS) werden jeweils für 1 Jahr auf iVenti.de gebucht und angezeigt. Sie können jederzeit bearbeitet und verlängert werden." />
						<Accordion title="Wozu dient der Bereich „Inspiration”?" content="Viele unserer Besucher:innen nutzen für ihre Suche einfach den komfortablen iVenti-Bereich „Inspiration“. Hier finden sie zu ausgewählten Eventarten wertvolle Anregungen. Und hier finden sie auch dich – mit deinem Inserat, das du punktgenau auf die Bedürfnisse deiner Kund:innen im gewählten Inspirationsbereich (z. B. „Hochzeit“) zugeschnitten hast. Ein Inserat im Bereich „Inspiration“ ist 1 Jahr sichtbar und mit den zusätzlichen, einmaligen Kosten von 49,– € netto verbunden."/>
						<Accordion title="Wozu gibt es den „Notdienst”-Button / Notdienst-Service?" content="Werde ein:e Retter:in in der Not! iVenti bietet eine Notdienst-Funktion an für Menschen, denen bei ihrer Eventplanung ein:e Dienstleister:in kurzfristig abgesprungen ist. Aktivieren Suchende auf iVenti.de den „Notdienst”-Button, werden ausschließlich Inserate von Anbieter:innen angezeigt, die zum angefragten Zeitpunkt einen Notdienst bieten. Wenn du als Anbieter:in einen Notdienst-Service für dein iVenti-Inserat bereitstellen möchtest, kannst du für die gesamte Laufzeit deines Inserates beliebig viele Tage festlegen. Deine Angaben kannst du jederzeit ändern. Der Notdienst ist eine Extra-Leistung für deine Kunden und macht aus dir ein:e Held:in. Es entstehen den Suchenden und den Anbieter:innen durch den Notdienst-Service keine Kosten." />
						<Accordion title="Welchen Vorteil haben Suchende bei iVenti?" content="Wer ein kleines oder großes Event plant, findet auf iVenti schnell und einfach regionale und überregionale Anbieter:innen aus der gesamten Eventbranche. Suchende können für sie interessante Inserate in Merklisten zusammenfassen (hierzu müssen sie einen kostenlosen iVenti-Account anlegen). Über den Button „Kostenlose Anfrage” können Suchende über ein Kontaktformular eine kostenlose Anfrage direkt an die Anbieter:innen versenden. Die Nutzung von iVenti ist für Suchende völlig kostenlos." />
					</div>
				</div>
			</section>

			<div className="lineBreakTopGrey">
					<img style={{display: "block"}} src={TopShapeGrey} />
			</div>

			

			<section style={{paddingTop: "70px", paddingBottom: "50px"}} className="accountTypes">
				<h2 style={{marginBottom: "0px"}}>DEIN INSERAT: „ZERO“ ODER „PLUS“</h2>
				<p style={{maxWidth: "800px", margin: "auto", paddingBottom: "70px"}} >Mit deinem iVenti-Account kannst du für dein Unternehmen werben. Hierzu bieten wir dir zwei Inseratgrößen: iVenti ZERO und iVenti PLUS.</p>
				<div style={{maxWidth: "1200px", margin: "auto"}}>
					<div>
						<span>iVENTI ZERO</span>
						<img src={imgZero} alt="iVENTI ZERO" />
					</div>
					<div>
						<span>iVENTI PLUS</span>
						<img src={imgPlus} alt="iVENTI PLUS" />
					</div>
				</div>
			</section>

			<div className="lineBreakBottomGrey">
					<img style={{display: "block"}} src={BottomShapeGrey} />
			</div>

			<section className="prices" style={{position: "relative", zIndex: "1"}}>
				<h2>DU HAST DIE WAHL</h2>
				<div className="priceCards">
					<Pricecard margin={true} heading="iVENTI ZERO">
						<PricecardElement content="Inserat mit 4 Quick-Infos und 1 Bild" caption="Neben 1 Foto werden der Name deines Produktes, dein Standort und zwei branchenspezifische Eigenschaften angezeigt." />
						<PricecardElement content="-" />
						<PricecardElement content="-" />
						<PricecardElement content="Unbegrenzte Anzahl von kostenlosen Direkt-Anfragen" caption="Du kannst eine unbegrenzte Anzahl von Direktanfragen kostenfrei von Interessent:innen erhalten. Hierzu gibt es in deinem Inserat einen Button „Gratis Anfrage”." />
						<PricecardElement content="Kontaktformular für Gratisanfragen" caption="In jedem iVenti-Inserat gibt es einen Button „Kostenlose Anfrage”. Hier können Suchende über ein Kontaktformular eine Gratis-Anfrage direkt an die Anbieter:innen versenden." />
						<PricecardElement content="Keine Kosten" caption="Das Inserat „iVenti ZERO” ist kostenlos. Wenn du dich umfangreicher präsentieren möchtest, kannst du es aber jederzeit in ein iVenti-PLUS-Inserat upgraden." />
						<PricecardElement content="Inserat wird hinten in der
Suchergebnis-Liste angezeigt" caption="Alle iVenti-ZERO-Inserate werden immer im hinteren Bereich von Suchergebnissenangezeigt. Im vorderen Bereich befinden sich alle iVenti-PLUS-Inserate." />
						<PricecardElement content="Regelmäßiger Wechsel
der Inseratplatzierung" caption="Die Positionen der iVenti-ZERO-Inserate und der iVenti-PLUS-Inserate werden jeweils täglich per Zufallsmodus gewechselt. So gibt es keine Bevorzugung einzelner Inserate." />
						<PricecardElement content="Inserat verlängerbar
und jederzeit erweiterbar auf iVenti plus" caption="Ein iVenti
-
Inserat wird für 1 Jahr
gebucht und kann im Anschluss immer wieder um 1
weiteres Jahr verlängert werden. So stellen wir für die Suchenden sicher, dass sich immer
ausschließlich aktive Dienstleister:innen auf dem iVenti
-
Portal befinden
" />
						<PricecardElement content="Inserat jederzeit von dir editierbar" caption="
In deine
m persönlichen Account hast du jederzeit Zugriff auf alle deine Inserate und
kannst sie während des gebuchten Jahres beliebig editieren und aktuellen Bedürfnissen
anpassen." />
						<PricecardElement content="-" />
						<PricecardElement content="-" />
						<PricecardElement content="-" />
						<PricecardElement content="-" />
						<PricecardElement>
							<div className="price">
								<div className="value">0 €/Jahr</div>
							</div>
						</PricecardElement>
					</Pricecard>

					<Pricecard style={{ color: "#756ead" }} heading="iVENTI FLAT" description={"1 JAHR LANG INSERATE SATT\nANGEBOT 222 TAGE GÜLTIG AB DEM 2.2.2022"}>
						<PricecardElement content="Inserat mit 4 Quick-Infos und 1 Bild" caption="Dein iVenti
-
PLUS
-
Inserat wird auf einer eigene
n Seite dargestellt und beinhaltet neben
den Basisinformationen wie dem Name deines Produktes, deinem Standort und zwei
branchenspezifischen Eigenschaften bis zu 18 Bilder und 2 Filme." />
						<PricecardElement content="Individuelle Textdarstellung
deiner Leistungen" caption="Zusätzlich zu den Basisinformationen, Bildern und Filmen kannst du dein Inserat mit
individuellen Texten und Zusatzinformationen wie Suchbegriffen, Anlässen und
Öffnungszeiten ausgestalten. So bekommen die Suchenden ein umfassendes Bild von
deinem Angebot."/>
						<PricecardElement content="Auflistung deiner Leistungen
in der Gesamtdarstellung" caption="Zeige den Suchenden deine gesamte Palette an Leistungen, die Du anbieten kannst.
Das informiert, inspiriert sie und macht Lust auf mehr."/>
						<PricecardElement content="Unbegrenzte Anzahl von kostenlosen Direkt-Anfragen" caption="Du kannst eine unbegrenzte Anzahl von Direktanfragen kostenfrei von
Interesse
nt:innen erhalten. Hierzu gibt es in deinem Inserat einen Button „Gratis Anfrage”." />
						<PricecardElement content="Kontaktformular für Gratisanfragen" caption="In jedem iVenti
-
Inserat gibt es einen Button „Kostenlose Anfrage”. Hier können
Suchende über ein Kontaktformular eine Gratis
-
Anfrage direkt an d
ie Anbieter:innen
versenden." />
						<PricecardElement content="Keine weiteren Kosten" caption='Neben der Jahresgebühr für dein Inserat fallen keine weiteren Kosten an. Es besteht
jedoch die freiwillige Möglichkeit, dein Inserat gegen eine Extragebühr zusätzlich in den
Bereichen „Inspiration
" und "Schnäppchen" zu platzieren.' />
						<PricecardElement content="Inserat wird vorne in der
Suchergebnis-Liste angezeigt" caption="Im Gegensatz zu iVenti
-
ZERO
-
Inseraten werden iVenti
-
PLUS
-
Inserate immer im
vorderen Bereich von Suchergebnissen angezeigt." />
						<PricecardElement content="Regelmäßiger Wechsel
der Inseratplatzierung" caption="Die Positionen der iVenti
-
ZERO
-
wie
auch iVenti
-
PLUS
-
Inseraten werden jeweils täglich
per Zufallsmodus gewechselt
.
So gibt es keine Bevorzugung einzelner Inserate." />
						<PricecardElement content="Inserat verlängerbar" caption="Ein iVenti
-
Inserat wird für 1 Jahr gebucht und kann im Anschluss immer wieder um 1
weiteres Jahr v
erlängert werden. So stellen wir für die Suchenden sicher, dass sich immer
ausschließlich aktive Dienstleister:innen auf dem iVenti
-
Portal befinden
." />
						<PricecardElement content="Inserat jederzeit von dir editierbar" caption="In deinem persönlichen Account hast du jederzeit Zugriff auf alle deine
Inserate und
kannst sie während des gebuchten Jahres beliebig editieren und aktuellen Bedürfnissen
anpassen." />
						<PricecardElement content="Notdienst-Verfügbarkeit: kann jederzeit
durch dich aktiviert oder deaktiviert werden" caption="Mit iVenti kannst du zum/zur Retter:in in der Not werden. Durch Angabe persönlicher
Notdienst
-
Tage hilfst du Suche
nden, denen ein:e Dienstleister:in kurzfristig abgesprungen
ist, schnell einen Ersatz zu finden. Dies bringt dir spontane Aufträge und dankbare
zusätzliche Kund:innen" />
						<PricecardElement content="Optionale Darstellung
im Bereich „Inspiration“" caption="Im Bereich „Inspiration” hast du d
ie Möglichkeit, dich ein Jahr lang in speziellen
Themenbereichen (Hochzeit, Tagung, Geburtstag etc.) mit einem punktgenau
zugeschnittenen Inserat zu präsentieren. Dieses Inserat ist mit Zusatzkosten verbunden." />
						<PricecardElement content="Optionale Darstellung
im Bereich „Schnäpppchen“
" caption="Im Bereich „Schnäppchen” hast du die Möglichkeit, dich 7 Tage lang mit einer Rabatt
-
Aktion zu präsentieren. Sonderangebote machen dich für Suchende besonders attraktiv. Ein
„Schnäppchen”
-
Inserat ist mit Zusatzkosten verbunden."/>
						<PricecardElement content="Angabe von Öffnungszeiten" />
						<PricecardElement>
							<div className="price" style={{ color: "#756ead" }}>
								<div className="value">149 €/Jahr</div>
								<span>Ein Jahr lang beliebig viele Inserate</span>
							</div>
						</PricecardElement>
					</Pricecard>

					<Pricecard margin={true} heading="iVENTI PLUS">

					<PricecardElement content="Inserat mit 4 Quick-Infos und 1 Bild" caption="Dein iVenti
-
PLUS
-
Inserat wird auf einer eigene
n Seite dargestellt und beinhaltet neben
den Basisinformationen wie dem Name deines Produktes, deinem Standort und zwei
branchenspezifischen Eigenschaften bis zu 18 Bilder und 2 Filme." />
						<PricecardElement content="Individuelle Textdarstellung
deiner Leistungen" caption="Zusätzlich zu den Basisinformationen, Bildern und Filmen kannst du dein Inserat mit
individuellen Texten und Zusatzinformationen wie Suchbegriffen, Anlässen und
Öffnungszeiten ausgestalten. So bekommen die Suchenden ein umfassendes Bild von
deinem Angebot."/>
						<PricecardElement content="Auflistung deiner Leistungen
in der Gesamtdarstellung" caption="Zeige den Suchenden deine gesamte Palette an Leistungen, die Du anbieten kannst.
Das informiert, inspiriert sie und macht Lust auf mehr."/>
						<PricecardElement content="Unbegrenzte Anzahl von kostenlosen Direkt-Anfragen" caption="Du kannst eine unbegrenzte Anzahl von Direktanfragen kostenfrei von
Interesse
nt:innen erhalten. Hierzu gibt es in deinem Inserat einen Button „Gratis Anfrage”." />
						<PricecardElement content="Kontaktformular für Gratisanfragen" caption="In jedem iVenti
-
Inserat gibt es einen Button „Kostenlose Anfrage”. Hier können
Suchende über ein Kontaktformular eine Gratis
-
Anfrage direkt an d
ie Anbieter:innen
versenden." />
						<PricecardElement content="Keine weiteren Kosten" caption='Neben der Jahresgebühr für dein Inserat fallen keine weiteren Kosten an. Es besteht
jedoch die freiwillige Möglichkeit, dein Inserat gegen eine Extragebühr zusätzlich in den
Bereichen „Inspiration
" und "Schnäppchen" zu platzieren.' />
						<PricecardElement content="Inserat wird vorne in der
Suchergebnis-Liste angezeigt" caption="Im Gegensatz zu iVenti
-
ZERO
-
Inseraten werden iVenti
-
PLUS
-
Inserate immer im
vorderen Bereich von Suchergebnissen angezeigt." />
						<PricecardElement content="Regelmäßiger Wechsel
der Inseratplatzierung" caption="Die Positionen der iVenti
-
ZERO
-
wie
auch iVenti
-
PLUS
-
Inseraten werden jeweils täglich
per Zufallsmodus gewechselt
.
So gibt es keine Bevorzugung einzelner Inserate." />
						<PricecardElement content="Inserat verlängerbar" caption="Ein iVenti
-
Inserat wird für 1 Jahr gebucht und kann im Anschluss immer wieder um 1
weiteres Jahr v
erlängert werden. So stellen wir für die Suchenden sicher, dass sich immer
ausschließlich aktive Dienstleister:innen auf dem iVenti
-
Portal befinden
." />
						<PricecardElement content="Inserat jederzeit von dir editierbar" caption="In deinem persönlichen Account hast du jederzeit Zugriff auf alle deine
Inserate und
kannst sie während des gebuchten Jahres beliebig editieren und aktuellen Bedürfnissen
anpassen." />
						<PricecardElement content="Notdienst-Verfügbarkeit: kann jederzeit
durch dich aktiviert oder deaktiviert werden" caption="Mit iVenti kannst du zum/zur Retter:in in der Not werden. Durch Angabe persönlicher
Notdienst
-
Tage hilfst du Suche
nden, denen ein:e Dienstleister:in kurzfristig abgesprungen
ist, schnell einen Ersatz zu finden. Dies bringt dir spontane Aufträge und dankbare
zusätzliche Kund:innen" />
						<PricecardElement content="Optionale Darstellung
im Bereich „Inspiration“" caption="Im Bereich „Inspiration” hast du d
ie Möglichkeit, dich ein Jahr lang in speziellen
Themenbereichen (Hochzeit, Tagung, Geburtstag etc.) mit einem punktgenau
zugeschnittenen Inserat zu präsentieren. Dieses Inserat ist mit Zusatzkosten verbunden." />
						<PricecardElement content="Optionale Darstellung
im Bereich „Schnäpppchen“
" caption="Im Bereich „Schnäppchen” hast du die Möglichkeit, dich 7 Tage lang mit einer Rabatt
-
Aktion zu präsentieren. Sonderangebote machen dich für Suchende besonders attraktiv. Ein
„Schnäppchen”
-
Inserat ist mit Zusatzkosten verbunden."/>
						<PricecardElement content="Angabe von Öffnungszeiten" />

						{/*
						<PricecardElement content="Inserat mit 4 Quick-Infos und 1 Bild" caption="Intrinsicly visualize adaptive sources whereas timely e-commerce. Professionally synergize market positioning best practices via." />
						<PricecardElement content="Individuelle Textdarstellung
deiner Leistungen"/>
						<PricecardElement content="Auflistung deiner Leistungen
in der Gesamtdarstellung"/>
						<PricecardElement content="Unbegrenzte Anzahl von kostenlosen Direkt-Anfragen" caption="Intrinsicly visualize adaptive sources whereas timely e-commerce. Professionally synergize market positioning best practices via." />
						<PricecardElement content="Kontaktformular für Gratisanfragen" caption="Lorem Ipsum" />
						<PricecardElement content="Keine weiteren Kosten" caption="Lorem Ipsum" />
						<PricecardElement content="Inserat wird vorne in der
Suchergebnis-Liste angezeigt" caption="Lorem Ipsum" />
						<PricecardElement content="Regelmäßiger Wechsel
der Inseratplatzierung" caption="Lorem Ipsum" />
						<PricecardElement content="Inserat verlängerbar" caption="Lorem Ipsum" />
						<PricecardElement content="Inserat jederzeit von dir editierbar" caption="Lorem Ipsum" />
						<PricecardElement content="Notdienst-Verfügbarkeit: kann jederzeit
durch dich aktiviert oder deaktiviert werden" />
						<PricecardElement content="Optionale Darstellung
im Bereich „Inspiration“" />
						<PricecardElement content="Optionale Darstellung
im Bereich „Schnäpppchen“
" />
						<PricecardElement content="Angabe von Öffnungszeiten

" /> */}

						<PricecardElement>
							<div className="price">
								<div>
									<div className="value">99 €/Jahr</div>
									<span>für das 1. Inserat</span>
								</div>
								<div>
									<div className="value">49 €/Jahr</div>
									<span>jedes weitere Inserat</span>
								</div>
							</div>
						</PricecardElement>
					</Pricecard>
				</div>
				<div style={{display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px"}}>
					<button onClick={() => navigate("/register")} style={{boxShadow: "1px 1px 5px #0000001a"}} className="submitAdButton">LEGE JETZT DEINEN<br />iVENTI-ACCOUNT AN</button>
				</div>

			</section>
		</main>
		}
		</>
	)
}

export default Landing;
