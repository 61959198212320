import React from 'react'
import Header from './provider/Header'

function ShowAd() {
  return (
    <>
    <Header headline={"TEST"}/>
    <div className="showAd">
        moin
    </div>
    </>
  )
}

export default ShowAd