import React, { useEffect, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

function DayPickerCustomFunction(props) {

	const WEEKDAYS_SHORT = ['So','Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

	function handleDayClick(day, { selected }) {
		if (selected) {
			const selectedIndex = props.selectedDays.findIndex(selectedDay =>
				DateUtils.isSameDay(selectedDay, day)
			)
			props.selectedDays.splice(selectedIndex, 1)
		  } else {
			props.selectedDays.push(day);
			console.log(day)
		}
		props.setSelectedDays([...props.selectedDays])
	}
	
	return (
			<DayPicker
				weekdaysShort={WEEKDAYS_SHORT}
				selectedDays={props.selectedDays}
				onDayClick={handleDayClick}
				firstDayOfWeek={1}
			/>
	)
}

export default DayPickerCustomFunction;
