import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CustomSelect = props => {
    return (
        <div className="CustomSelect" style={{width:'50%'}}>
            <FormControl sx={{ display:'flex' }}>
                <Select disabled={props.disabled}
                value={props.select}
                onChange={(e) => {
                    if(props.state === 1){
                        props.setSelect(e.target.value);
                        props.runProgress({state: props.state, data: e.target.value});
                    }else if(props.state === 2){
                        console.log(e.target.value, props.state)
                        props.runProgress({state: props.state, data: e.target.value});
                    }
                }}
                displayEmpty
                sx={{
                    backgroundColor:'#fff',
                    fontFamily: 'Spartan, sans-serif',
                    fontSize:'14px',
                    border:'none !important',
                    ['& fieldset']:{
                        border:'none !important',
                        boxShadow: '1px 1px 5px #0000001a',
                        
                    },
                    ['& > div:first-child']:{
                        padding:'20px 32px 20px 20px !important',
                    }
                }}
                >
                {props.children}
                </Select>
            </FormControl>
        </div>
    );
}

export default CustomSelect;