import React, { useEffect, useState } from 'react';
import './AdsProfile.css'
import { getDocs, query, collection, where, deleteDoc, doc } from "firebase/firestore"; 
import { db } from '../../Firebase'
import SmallAd from './SmallAd';
import AlarmIcon from '../../img/alarmIcon.svg'
import AlarmIconActive from '../../img/alarmIconActive.svg'
import EditIcon from '../../img/editIcon.svg'
import DeleteIcon from '../../img/deletIcon.svg'
import PopUp from '../PopUp';
import { useUserContext } from '../../context/UserContext';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';

function AdsProfile() {

	const navigate = useNavigate()

	const { user } = useUserContext()

	const [ads, setAds] = useState([])
	const [inspos, setInspos] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState("")
	const [empty, setEmpty] = useState(false)

	async function getAds(){
		const docs = getDocs(query(collection(db, "posts"), where("state", "==", "running"), where("uid", "==", user.uid)))
		const docs2 = getDocs(query(collection(db, "posts"), where("state", "==", "check"), where("uid", "==", user.uid)))

		const docs3 = getDocs(query(collection(db, "inspos"), where("state", "==", "running"), where("uid", "==", user.uid)))
		const docs4 = getDocs(query(collection(db, "inspos"), where("state", "==", "check"), where("uid", "==", user.uid)))

		var [docsSnapshot, docs2Snapshot, docs3Snapshot, docs4Snapshot] = await Promise.all([
			docs,
			docs2,
			docs3,
			docs4
		])

		/*

		if(docsSnapshot.size === 0 && docs2Snapshot.size === 0){
			return false
		} else if(docsSnapshot.size !== 0 && docs2Snapshot.size === 0){
			return docsSnapshot.docs
		} else if(docsSnapshot.size === 0 && docs2Snapshot.size !== 0) {
			return docs2Snapshot.docs
		} else if (docsSnapshot.size !== 0 && docs2Snapshot.size !== 0){
			return docsSnapshot.docs.concat(docs2Snapshot.docs)
		}

		*/

		return {posts: docsSnapshot.docs.concat(docs2Snapshot.docs), inspos: docs3Snapshot.docs.concat(docs4Snapshot.docs)}
	}

	function deleteAd(e){
		var id = e.currentTarget.getAttribute("data-value")

		deleteDoc(doc(db, "posts", e.currentTarget.getAttribute("data-value"))).then(() => {
			setAds(ads.filter((item) => item.id !== id))
		}).catch((err) => {
			console.log(err)
		})
	}

	function editAd(e) {
		navigate(`/dashboard/editAds/${e.currentTarget.getAttribute("data-value")}`)
	}

	function deleteInspo(e){
		var id = e.currentTarget.getAttribute("data-value")

		deleteDoc(doc(db, "inspos", e.currentTarget.getAttribute("data-value"))).then(() => {
			setInspos(inspos.filter((item) => item.id !== id))
		}).catch((err) => {
			console.log(err)
		})
	}

	function editInspo(e) {
		console.log("edit")
	}

	useEffect(() => {
		getAds().then((e) => {
			if(e.posts.length === 0 && e.inspos.length === 0){
				setLoading(false)
				setEmpty(true)
			} else {
				if(e.posts.length !== 0){
					e.posts.forEach((item) => {
						ads.push(item)
						setAds([...ads])
					})
				}
				if(e.inspos.length !== 0){
					e.inspos.forEach((item) => {
						inspos.push(item)
						setInspos([...inspos])
					})
				}
				setLoading(false)
			}	
		})
	}, [])
	

	return (
		<>
		{error && <PopUp message={error} setError={setError} />}
		{loading ? 
		<Loader />
		:
		<div className="AdsProfile">
			<div className="headline">
			{empty && <h2>DU HAST KEINE INSERATE</h2>}	
			</div>
			{ads.length !== 0 && <h2 style={{paddingBottom: "0px"}}>INSERATE IN RUBRIKEN</h2>}
			<div className="ap_adsContainer">
				{ads.map((item, index) => (
					<div style={{marginTop: "50px"}} className="ap_item" key={index}>
					<div className="ap_item">
						<SmallAd headline={item.data().title} service={item.data().serviceQuick} name={item.data().nameQuick} categorie={item.data().service} address={item.data().zip} image={item.data().img[0].url} type={item.data().type}/>
						<div className="ap_editBar">
							<div className="ap_info"></div>
							<div onClick={item.data().state !== "check" ? (e) => editAd(e) : () => setError("Anzeige wird geprüft")} data-value={item.id} className="ap_delete">
								{item.data().alarm ? <img style={{width: "40px"}} src={AlarmIconActive} /> : <img style={{width: "40px"}} src={AlarmIcon} />} 
							</div>
							<div onClick={item.data().state !== "check" ? (e) => deleteAd(e) : () => setError("Anzeige wird geprüft")} data-value={item.id} className="ap_delete">
								<img src={DeleteIcon} />
							</div>
							<div onClick={item.data().state !== "check" ? (e) => editAd(e) : () => setError("Anzeige wird geprüft")} data-value={item.id} className="ap_edit">
								<img src={EditIcon} />
							</div>
						</div>
					</div>
					{item.data().state === "check" && <div style={{textAlign: "left", fontWeight: "700"}}>Deine Anzeige geht bald online :)</div>}
					{item.data().state === "running" && <div style={{textAlign: "left", fontWeight: "700"}}>{item.data().start.split("/")[1]}.{item.data().start.split("/")[0]}.{item.data().start.split("/")[2]} - {item.data().end.split("/")[1]}.{item.data().end.split("/")[0]}.{item.data().end.split("/")[2]}</div>}
					</div>
				))}
			</div>

			{/* TEST */}

			{inspos.length !== 0 && <h2 style={{paddingBottom: "0px"}}>INSERATE IN INSPIRATIONEN</h2>}
			<div className="ap_adsContainer">
				{inspos.map((item, index) => (
					<div style={{marginTop: "50px"}} className="ap_item" key={index}>
						<div className="ap_item">
							<SmallAd headline={item.data().title} service={item.data().service} name={item.data().nameQuick} categorie={item.data().servicesName} address={item.data().zip} image={item.data().img[0].url} type={item.data().type}/>
							<div className="ap_editBar">
								<div className="ap_info"></div>
								<div onClick={item.data().state !== "check" ? (e) => deleteInspo(e) : () => setError("Anzeige wird geprüft")} data-value={item.id} className="ap_delete">
									<img src={DeleteIcon} />
								</div>
								<div onClick={item.data().state !== "check" ? (e) => editInspo(e) : () => setError("Anzeige wird geprüft")} data-value={item.id} className="ap_edit">
									<img src={EditIcon} />
								</div>
							</div>
						</div>
					{item.data().state === "check" && <div style={{textAlign: "left", fontWeight: "700"}}>Deine Anzeige geht bald online :)</div>}
					{item.data().state === "running" && <div style={{textAlign: "left", fontWeight: "700"}}>{item.data().start.split("/")[1]}.{item.data().start.split("/")[0]}.{item.data().start.split("/")[2]} - {item.data().end.split("/")[1]}.{item.data().end.split("/")[0]}.{item.data().end.split("/")[2]}</div>}
					</div>
				))}
			</div>
		</div>
		}
		</>
	)
}

export default AdsProfile;
