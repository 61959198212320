import React, { useEffect, useState } from 'react'
import Header from '../provider/Header'
import { arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import { db, auth } from '../../Firebase'

import { get } from 'firebase/auth'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

function CheckAds() {

	const [documents, setDocuments] = useState([])

	async function getDocuments(){

		const posts = getDocs(query(collection(db, "posts"), where("state", "==", "check")))
		const inspos = getDocs(query(collection(db, "inspos"), where("state", "==", "check")))

		const [postsSnapshot, insposSnapshot] = await Promise.all([
			posts,
			inspos
		])

		return postsSnapshot.docs.concat(insposSnapshot.docs)

	}


	useEffect(() => {

		getDocuments().then((e) => {
			setDocuments(e)
		})

	}, [])
	


	return (
		<>
			<div style={{marginBottom: "30px"}} className="checkAds">
				<Header headline={"ADMIN"} />
			</div>
			<TableContainer sx={{maxWidth: "80%", margin: "auto"}} component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>Kunde</TableCell>
						<TableCell align="left">Titel</TableCell>
						<TableCell align="left">Kategorie</TableCell>
						<TableCell align="left">Leistung</TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
					{documents.map((row) => (
						<TableRow
						key={row.id}
						sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
						onClick={(e) => console.log(e.currentTarget)}
						>
						<TableCell component="th" scope="row">
							{row.data().uid}
						</TableCell>
						<TableCell align="left">{row.data().title}</TableCell>
						<TableCell align="left">{row.data().servicesName}</TableCell>
						<TableCell align="left">{row.data().service}</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>

			

		</>	
	)
}

export default CheckAds