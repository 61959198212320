import React, { useRef, useState } from 'react';
import Header from './Header';
import CircularProgress from '@mui/material/CircularProgress';
import { useUserContext } from '../../context/UserContext';
import { useNavigate, Navigate, Link } from 'react-router-dom'
import PopUp from '../PopUp';
import { sendEmailVerification, updateProfile } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../Firebase'


function RegisterProvider() {

	const emailRef = useRef()
	const legal = useRef()
	const nameRef = useRef()
	const passwordRef = useRef()
    const passwordRepeatRef = useRef()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

	const navigate = useNavigate()

	const { registerUser, user } = useUserContext()

	async function signInNewUser(){
        setLoading(true)

		if(nameRef.current.value === "" || emailRef.current.value === "" || passwordRef.current.value === "" || passwordRepeatRef.current.value === ""){
			setError("Bitte fülle alle Pflichtfelder aus")
			setLoading(false)
			return
		}

		if(!legal.current.checked){
			setError("Akzeptiere unsere Datenschutzerlärung und AGB um ein Konto zu erstellen")
			setLoading(false)
			return
		}

		if(passwordRef.current.value !== passwordRepeatRef.current.value){
			setError("Passwörter stimmen nicht überein")
			setLoading(false)
			return
		}

        registerUser(emailRef.current.value, passwordRef.current.value).then((data) => {
            sendEmailVerification(data.user)
			updateProfile(data.user, {
				displayName: nameRef.current.value
			}).then(() => {
				setDoc(doc(db, "userData", data.user.uid), {
					flat: false,
					type: "Anbieter"
				}).then(() => {
					navigate("/dashboard")
				})
			})
        }).catch((err) => {
            setError("Es ist ein Fehler aufgetreten")
            setLoading(false)
        })
	}

	return (
		<>
			{error && <PopUp message={error} setError={setError}/>}
			<div className="LoginProvider">
				<Header headline={"WILLKOMMEN BEI iVENTI"} text={"Zeige deine Region, was du zu bieten hast!"} color={"#F6F6F5"} />
				<div className="headlineNormal">Konto erstellen</div>
				<div style={{maxWidth: "90%", width: "500px", margin: "auto", paddingTop: "20px", textAlign: "center"}}>
					<input ref={nameRef} className="textField" type="text" placeholder="Dein Name*" />
					<input ref={emailRef} className="textField" type="text" placeholder="E-Mail-Adresse*" />
					<input ref={passwordRef} className="textField" type="password" placeholder="Passwort*" />
                    <input ref={passwordRepeatRef} className="textField" type="password" placeholder="Passwort wiederholen*" />
					<div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "25px 0 10px 0"}} className="legal">
						<input type="checkbox" ref={legal}/>
						<div style={{paddingLeft: "5px"}}>Ich akzeptiere die Datenschutzerklärung und AGB*</div>
					</div>
				</div>
				<div style={{cursor: "pointer" ,width: "100%", display: "flex", alignItems: "center", margin: "20px 0 50px 0", flexDirection: "column"}}>
					<Link to="/login" style={{marginBottom: "20px" ,fontSize: "12px"}}>Du hast bereits einen Account? Hier anmelden</Link>
					{loading ? 
						<button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
						:
						<button onClick={signInNewUser} className="submitAdButton">Registrieren</button>
					}
				</div>
			</div>
		</>
	)
}

export default RegisterProvider;
