import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import "./Profile.css"
import { useUserContext } from '../../context/UserContext';
import { updateProfile } from 'firebase/auth'
import { Navigate } from 'react-router-dom'
import PopUp from '../PopUp';

function Profile() {

	const [name, setName] = useState("")

	const [loading, setLoading] = useState(false)
	const [loadingData, setLoadingData] = useState(false)
	const [error, setError] = useState("")

	const { user, forgotPassword } = useUserContext()
	
	async function reset(){
		setLoading(true)
        forgotPassword(user.email).then(() => {
            setError("Wir haben dir eine E-Mail gesendet")
            setLoading(false)
        }).catch((err) => {
            setError("Es ist ein Fehler aufgetreten", err)
            setLoading(false)
        })
	}

	function saveData(){
		setLoadingData(true)
		updateProfile(user, {
			displayName: name
		}).then(() => {
			setLoadingData(false)
		})
	}

	useEffect(() => {
		setName(user.displayName)
	}, [])
	

	return (
		<>
		{error && <PopUp message={error} setError={setError}/>}
		{!user ? <Navigate to="/login"/> :
		<div style={{maxWidth: "1200px", margin: "auto", width: "90%"}} className="Profile">
			<div style={{margin: "20px 0 20px 0"}} className="headlineNormal">Dein Profil</div>
			<input onChange={(e) => setName(e.target.value)} className="textField" type="text" placeholder="Dein Name" value={name}/>
			<div className="dp_inputFields">
				<input disabled className="textField" type="text" placeholder="Dein Name" value={user.email}/>
				<input disabled className="textField" type="password" placeholder="" value={"***********"}/>
			</div>
			<div className="buttonContainer" style={{display: "flex", justifyContent: "center", margin: "40px 0 40px 0"}}>
			{loading ? 
				<button style={{margin: "2px"}} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
				:
				<button style={{margin: "2px"}} onClick={reset} className="submitAdButton">Passwort ändern</button> 
			}
			{loadingData ? 
				<button style={{margin: "2px"}} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
				:
				<button style={{margin: "2px"}} onClick={saveData} className="submitAdButton">Speichern</button> 
			}
			</div>
		</div>
		}
		</>
	)
}

export default Profile;
