import React, { useState } from 'react'
import { animated, useTransition } from 'react-spring'

function Gallery() {

    const [word, setWord] = useState('Moin')

    const toggleState = () => {
        if(word === 'Moin'){
            setWord('Fred')
        } else {
            setWord('Moin')
        }
    }

    const transition = useTransition(word, {
        from:{opacity: 0},
        enter: {opacity: 1}
    })

    return (
        <div>
            {transition((style, item) => 
                <animated.div style={style}>{word}</animated.div>
            )}
            <button onClick={() => toggleState()}>Klicks</button>
        </div>
    )
}

export default Gallery